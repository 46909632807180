import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../components/layout"
import BgImage from '../components/bg-image'
import LinkBlock from '../components/link-block'

const Work = () => (
  <Layout>
    <Helmet title="Our Work" />
    <BgImage image="/assets/pool-interiors.jpg" className="top">
      <h1>Our Work</h1>
    </BgImage>
    <section className="content">
      <h1>We can renovate your pool</h1>
      <p>
        We're a team of experts dedicated to providing you with the best
        possible renovation not just to your swimming pool but also the
        surrounding pool landscape. We want to work closely with you to not only
        meet your budget, but also to create the dream pool that will provide
        enjoyment for years to come. We can resurface any pool no matter how big
        the rust patch or number of drummy spots.
      </p>
      <LinkBlock
        title="Pool Interiors"
        desc=""
        items={[
          {
            title: 'Pool Resurfacing',
            url: '/work/pool-resurfacing',
            desc: 'Make an old pool look new and rejuvenated.',
          },
          {
            title: 'Tiling',
            url: '/work/tiling',
            desc: 'Transform your swimming pool into an artisan masterpiece',
          },
          {
            title: 'Waterproof Membranes',
            url: '/work/waterproof-membranes',
            desc: 'Waterproof your pool for all conditions',
          },
        ]}
      />
      <LinkBlock
        title="Pool Surrounds"
        desc=""
        items={[
          {
            title: 'Paving',
            url: '/work/paving',
            desc:
              'Find the perfect finish to contrast nicely with the surface of the pool',
          },
          {
            title: 'Decking',
            url: '/work/decking',
            desc:
              'An extension to home’s exterior, or something unique and glamorous',
          },
          {
            title: 'Sealing',
            url: '/work/sealing',
            desc: 'Protect your brand-new deck, or renovate an old surface',
          },
          {
            title: 'Water Features and Feature Walls',
            url: '/work/features',
            desc: 'Make your pool even more attractive',
          },
          {
            title: 'Landscaping and Turfing',
            url: '/work/landscaping-turfing',
            desc: 'Bring your pool surrounds to life',
          },
        ]}
      />
      <LinkBlock
        title="Outdoor living"
        desc=""
        items={[
          {
            title: 'Fencing',
            url: '/work/fencing',
            desc: 'Safety, style, and value for your home',
          },
          {
            title: 'Pool Hoists & Aids',
            url: '/work/hoists-aids',
            desc: 'A little bit of help to enjoy your pool',
          },
          {
            title: 'Outdoor Rooms',
            url: '/work/outdoor-rooms',
            desc: 'Make your swimming pool even more comfortable',
          },
        ]}
      />
      <LinkBlock
        title="Equipment"
        desc=""
        items={[
          {
            title: 'Filtration & Pumps',
            url: '/work/filtration-pumps',
            desc: 'Keeping your pool water fresh and clean',
          },
          {
            title: 'Pool Cleaners',
            url: '/work/pool-cleaners',
            desc: 'Keeping your pool clean and safe all year round',
          },
          {
            title: 'Heating & Lighting',
            url: '/work/heating-lighting',
            desc: 'Enjoy your swimming pool in any season',
          },
        ]}
      />
    </section>
  </Layout>
)

export default Work
