import React from 'react'
import Link from 'gatsby-link'

import * as  styles from './link-block.module.css'

export default class LinkBlock extends React.Component {
  render () {
    const {items, title, desc} = this.props
    return (
      <div className={styles.blocks}>
        <div className={styles.title}>
          <h2>{title}</h2>
          <p>{desc}</p>
        </div>
        <div className={styles.content}>
          {items.map((item, index) => {
            return (
              <Link className={styles.block} to={item.url} key={index}>
                <div className={styles.blockTitle}>{item.title}&nbsp;<span aria-hidden='true'>→</span></div>
                {item.desc && <p>{item.desc}</p>}
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
};
